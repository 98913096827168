.admin {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  margin-top: 5rem; }
  .admin .content {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    width: 100%; }
    .admin .content .table-header {
      width: 100%;
      display: grid;
      grid-template-columns: 3fr 0.6fr 0.3fr 0.8fr;
      grid-template-rows: min-content; }
      .admin .content .table-header .product.name,
      .admin .content .table-header .product.id,
      .admin .content .table-header .product.stock,
      .admin .content .table-header .product.action {
        text-transform: uppercase;
        grid-row: 1;
        padding: 0.5rem 1rem;
        background-color: #ddd; }
      .admin .content .table-header .product.stock,
      .admin .content .table-header .product.action {
        text-align: center; }
      .admin .content .table-header .product.name {
        grid-column: 1; }
      .admin .content .table-header .product.id {
        grid-column: 2/5; }
      .admin .content .table-header .product.stock {
        grid-column: 3; }
      .admin .content .table-header .product.action {
        grid-column: 4; }
      .admin .content .table-header .table-content {
        grid-row: 2;
        grid-column: 1/5; }
        .admin .content .table-header .table-content ul {
          padding: 1rem 2rem; }
          .admin .content .table-header .table-content ul li {
            display: grid;
            grid-template-columns: 0.2fr 3fr 0.6fr 0.3fr 0.7fr;
            grid-template-rows: 100%;
            list-style: none;
            border-bottom: 1px solid #ddd;
            align-items: center;
            height: 6rem; }
            .admin .content .table-header .table-content ul li div {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 auto; }
            .admin .content .table-header .table-content ul li h2 {
              padding: 0 3rem;
              font-size: 2rem;
              text-transform: capitalize; }
            .admin .content .table-header .table-content ul li:hover {
              background-color: #f2f2f2; }
            .admin .content .table-header .table-content ul li img {
              height: 100%;
              max-height: 4rem; }
    .admin .content #action,
    .admin .content #qty {
      text-align: center; }
  .admin .product-thumbnail img {
    width: 100px; }

.ant-table-row > :first-child {
  overflow: hidden;
  padding: 5px 0 5px 10px !important; }

.alignRight {
  text-align: left; }

.alignCenter {
  text-align: center !important; }

/* button.btn {
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: transparent; }
  button.btn:focus {
    outline: none; }
  button.btn i {
    font-style: normal;
    font-family: 'FontAwesome';
    font-size: 2rem; }
  button.btn i.icon-edit:before {
    content: '\f044'; }
  button.btn i.icon-trash:before {
    content: '\f1f8'; }
  button.btn.btn {
    border-radius: 50%;
    color: #575757;
    transition: background 0.5s ease; }
    button.btn.btn:hover {
      color: #fff !important; }
  button.btn#delete-btn:hover {
    background-color: rgba(204, 65, 65, 0.8); }
  button.btn#edit-btn:hover {
    background-color: rgba(250, 165, 0, 0.8); } */

.success {
  background: #f7ffed !important;
  border: 2px solid #b8ec8f; }

.error {
  background: #fff1ef !important;
  border: 2px solid #feccc6; }
