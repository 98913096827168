.spinner-container {
  position: fixed;
  background-color: #ffffffc2;
  height: 100vh;
  width: 100vw;
  z-index: 999; }

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 13; }
  .spinner > div {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    background-color: #dddddd;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s; }
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
