*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-size: 62.5%;
	height: 100%;
}

body {
	font-weight: 400;
	line-height: 1.3;
	/* background-color: #ffffff !important; */
	/* font-family: "Jost", Roboto, "Helvetica Neue", Arial, sans-serif !important; */
	overflow-y: auto;
	box-sizing: border-box;
	position: relative;
	height: 100%;
}

#root {
	height: 100%;
}

img {
	max-width: 100%;
	max-height: 200px;
	object-fit: contain;
}

a {
	text-decoration: none;
	color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: 500;
}

.placeholder {
	height: 90rem;
}

.full-width {
	width: 100vw;
	height: 100%;
	position: relative;
}

.container {
	max-width: 140rem;
	width: 100%;
	margin: 0 auto;
}

.btn {
	border: none;
}
.btn:active {
	outline: none;
}
.btn:focus {
	outline: none;
}

.cancel-btn,
.submit-btn {
	cursor: pointer;
	width: 100%;
	border: none;
	padding: 2rem;
	border-radius: 6px;
	text-align: center;
	font-size: 2rem;
	font-weight: 700;
	text-transform: uppercase;
}
.cancel-btn:disabled,
.submit-btn:disabled {
	background: #ddd !important;
}

.primary {
	background: #1e5a99;
	color: #fff;
}

.secondary {
	background: #dddddd;
	color: #000;
}

.field-error {
	margin-top: 5px;
	color: #e55f6e;
}

.empty-field {
	margin-top: 5px;
	height: 2.3rem;
	font-size: 1.5rem;
	line-height: 1;
}

.mt-5 {
	margin-top: 5rem;
}

.home {
	position: relative;
	display: flex;
	padding: 2rem 0;
}

.navbar {
	max-width: 12rem;
	margin-right: 1.5rem;
	background-color: #f3f3f3;
}
.navbar h2 {
	color: #333;
	margin-bottom: 1rem;
}
.navbar ul {
	text-transform: uppercase;
}
.navbar li {
	list-style: none;
	font-size: 1.5rem;
	font-weight: 400;
	cursor: pointer;
}
.navbar li:hover {
	background-color: #ddd;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 1;
	padding: 0 1rem;
}
.header img.logo {
	width: 15rem;
	margin: auto;
}
.header .header-content {
	font-size: 1.5rem;
	order: 1;
	width: 100%;
	padding: 1rem 0;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.header .justify.between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.header .login-text {
	font-size: 2rem;
	font-weight: 900;
	color: #aaaaaa;
	width: 15rem;
	text-align: right;
	order: 2;
	padding-right: 1.5rem;
	transition: color 0.3s;
	cursor: pointer;
	text-decoration: none;
}
.header .login-text:hover {
	color: #19aae1;
}
.header .left.text {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 470px;
	font-weight: 600;
	color: #999999;
	vertical-align: middle;
	line-height: 1.7;
	height: max-content;
}
.header .left.text span p {
	margin: 0;
}
.header .left.text .logo-wrapper {
	height: 100%;
	margin-left: 15px;
	outline: none;
}

.overlay {
	mix-blend-mode: luminosity;
}

.hero-section {
	position: relative;
}
.hero-section .hero-img {
	color: #ffffff;
	height: 20rem;
	overflow: hidden;
}
.hero-section .hero-img.hero-bg {
	background: linear-gradient(to right, #ff7402cb, #2744ffa8),
		url('../images/hero-background.jpg');
	align-items: center;
	background-size: cover;
	padding: 4rem 2rem;
}
.hero-section .hero-img .hero-content {
	position: absolute;
	left: 50%;
	top: 47%;
	transform: translate(-50%, -50%);
	text-align: center;
}
.hero-section .hero-img .hero-content img.logo {
	width: 15rem;
}
.hero-section .hero-img .hero-content > h2 {
	width: 40rem;
	font-size: 2.2rem;
	color: #ffffff;
}

.h-4 {
	height: 5rem;
}

.mt-1 {
	margin-top: 1rem;
}

.cr-333 {
	color: #333;
}

.cr-fff {
	color: #aaa;
}

.cr-ddd {
	color: #ddd;
}

.cr-f00 {
	color: #f00;
}

.fs-12 {
	font-size: 1.2rem;
}

.fs-15 {
	font-size: 1.5rem;
}

.fs-16 {
	font-size: 1.6rem;
}

.fs-2 {
	font-size: 2rem;
}

.fw-400 {
	font-weight: 400;
}

.fw-600 {
	font-weight: 600;
}

.d-block {
	display: block;
}

.ptb-1 {
	padding: 1rem 0 1rem 0;
}

.plr-15 {
	padding: 0 1.5rem 0 1.5rem;
}

.mb-5 {
	margin-bottom: 5px;
}

.mb-1 {
	margin-bottom: 1rem;
}

.card-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #fff;
	border: 1.5px solid #e7ebf3;
}
.card-wrapper .card-body {
	position: relative;
	width: 100%;
	height: 100%;
	cursor: pointer;
}
.card-wrapper .card-body .card.label {
	position: absolute;
	background-color: red;
	font-size: 1.5rem;
	padding: 0.5rem 2rem;
	color: #ffffff;
	text-transform: uppercase;
	line-height: 1;
	margin: 0;
	z-index: 1;
}
.card-wrapper .card-body .card.img-container {
	max-height: 200px;
	min-height: 200px;
	margin: 0 auto;
	align-items: center;
	display: flex;
	justify-content: center;
	padding: 2rem;
	position: relative;
	overflow: hidden;
	object-fit: cover;
}
.card-wrapper .card-body .card.img-container .img {
	position: relative;
	display: block;
	margin-top: 20px;
}
.card-wrapper .card-body .card.text-container {
	margin-top: 0.5rem;
}
.card-wrapper .card-body .card.text-container .text div > div.add-to-cart-icon {
	opacity: 0.5;
	transition: opacity 0.3s ease;
}
.card-wrapper
	.card-body
	.card.text-container
	.text
	div
	> div.add-to-cart-icon:hover {
	opacity: 1;
}
.card-wrapper .card-body .card.text-container .title {
	text-transform: uppercase;
	min-height: 40px;
	max-height: 40px;
	overflow: hidden;
}

.products-section {
	position: relative;
	min-height: 80rem;
	overflow-y: auto;
}
.products-section .products-row {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-row-gap: 1.5rem;
	grid-column-gap: 1.5rem;
}
.products-section .products-row > a {
	text-decoration: none;
}

.order-btn {
	cursor: pointer;
	text-align: center;
	color: #ffffff;
	font-size: 1.5rem;
	width: 13rem;
	background-color: #ffba00;
	padding: 1rem 2rem;
	border-radius: 0.5rem;
}

.detail-section {
	position: relative;
	margin: 2rem auto;
	background: #fff;
	width: 100%;
}
.detail-section .bottom {
	padding-top: 3rem;
}
.detail-section .description-text {
	padding: 5rem 0;
	font-size: 2rem;
}

.detail-container {
	display: flex;
	flex-direction: column;
	padding: 3rem;
	height: 100%;
	width: 100%;
}
.detail-container .detail-content {
	display: flex;
	justify-content: space-between;
}
.detail-container .detail-content .img {
	width: 40%;
	display: flex;
	justify-content: center;
	padding-bottom: 5rem;
}
.detail-container .detail-content .text-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-left: 2em;
}
.detail-container .detail-content .text-container .title {
	font-size: 2.2rem;
	font-weight: 400;
	vertical-align: middle;
	margin-bottom: 1rem;
	border-bottom: 1px solid #eeeeee;
	padding-bottom: 2rem;
	text-transform: capitalize;
	color: #888;
}
.detail-container .detail-content .text-container h3.product-price {
	font-weight: 700;
	font-size: 2.7rem;
	display: flex;
	align-items: center;
	line-height: 1.7;
	margin-bottom: 1rem;
}
.detail-container .detail-content .text-container h3.product-price .old-price {
	margin-left: 2rem;
	vertical-align: middle;
	color: #aaaaaa;
	font-weight: normal;
	font-size: 1.6rem;
	text-decoration: line-through;
}
.detail-container .detail-content .text-container h3.product-price .ea-text {
	font-size: 2.5rem;
}
.detail-container .detail-content .text-container h4 {
	text-transform: capitalize;
	margin-bottom: 0.7rem;
	font-size: 1.3rem;
}
.detail-container .detail-content .text-container .buy-quantity-container {
	width: fit-content;
	margin-bottom: 3rem;
}
.detail-container
	.detail-content
	.text-container
	.buy-quantity-container
	.boxes-container {
	display: flex;
	justify-content: space-between;
	max-width: 46rem;
}
.detail-container
	.detail-content
	.text-container
	.buy-quantity-container
	.boxes-container
	div:first-child
	.active {
	background-color: red;
}
.detail-container .detail-content .text-container .buy-quantity-container .box {
	margin-right: 1rem;
	cursor: pointer;
	font-size: 1.2rem;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0.5rem;
	width: 10rem;
	height: 6rem;
	border: 0.12rem solid #dddddd;
}
.detail-container
	.detail-content
	.text-container
	.buy-quantity-container
	.box.active {
	background-color: #1e5a99;
	color: #fff;
}
.detail-container
	.detail-content
	.text-container
	.buy-quantity-container
	.box
	.price {
	margin-top: 0.3rem;
	font-size: 1.3rem;
	font-weight: 700;
}

.checkout-section {
	margin: 0 auto;
	position: relative;
	background-color: #ffffff;
	border: 0.15rem solid #dddddd;
}
.checkout-section .checkout.content {
	padding: 7rem;
	font-size: 2.5rem;
}
.checkout-section .checkout.content .checkout-cart {
	display: flex;
	justify-content: space-between;
}
.checkout-section
	.checkout.content
	.checkout-cart
	.checkout-cart-container
	.customerNote {
	margin-bottom: 5rem;
}
.checkout-section
	.checkout.content
	.checkout-cart
	.checkout-cart-container
	.customerNote
	textarea {
	width: 100%;
	padding: 2rem;
	font-size: 2rem;
}
.checkout-section .checkout.content .checkout-cart .cancel-btn {
	display: none;
}
.checkout-section .checkout.content .checkout-cart-container {
	display: flex;
	flex-direction: column;
	width: 70%;
	justify-content: space-between;
}
.checkout-section .checkout.content .product-table-head {
	font-size: 1rem;
	display: grid;
	grid-template-columns: 5fr 1fr 1fr 0.7fr 0.7fr;
	grid-template-rows: max-content;
	margin-bottom: 4rem;
}
.checkout-section .checkout.content .product-table-head #hd-title {
	font-size: 3rem;
	grid-row: 1;
	grid-column: 1/5;
}
.checkout-section .checkout.content .product-table-head #hd-pr,
.checkout-section .checkout.content .product-table-head #hd-qt,
.checkout-section .checkout.content .product-table-head #hd-up,
.checkout-section .checkout.content .product-table-head #hd-tp {
	grid-row: 2;
	padding: 2rem 1rem 1rem;
	border-bottom: 1px solid #ddd;
	gap: 0.5rem;
}
.checkout-section .checkout.content .product-table-head #hd-tp {
	grid-column: 4/6;
}
.checkout-section .checkout.content .product-table-head .product-table-body {
	grid-row: 3;
	grid-column: 1/6;
}
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.product-content {
	grid-row: 2;
	grid-column: 1/5;
	display: grid;
	grid-template-columns: 0.5fr 5fr 2fr 1fr 1fr 0.2fr;
	grid-gap: 0.5rem;
	font-size: 2rem;
	padding: 1rem;
	border-bottom: 1px solid #ddd;
	align-items: center;
}
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.product-content
	img {
	height: 50px;
}
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.product-content
	svg {
	transition: all 0.3s ease;
	cursor: pointer;
	height: 25px;
	opacity: 0.5;
}
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.product-content
	svg:hover {
	opacity: 1;
}
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.fw400 {
	font-weight: normal;
}
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.p01 {
	padding: 0 1rem;
}
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.pr-img {
	width: 100%;
}
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.pr-qty {
	align-items: center;
	padding-left: 0;
}
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.pr-qty
	.s-container {
	/* width: 2rem; */
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.pr-qty
	.s-container
	#plus:focus,
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.pr-qty
	.s-container
	#min:focus,
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.pr-qty
	.s-container
	#qtyItem {
	outline: none;
}
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.pr-qty
	.s-container
	#plus,
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.pr-qty
	.s-container
	#min {
	display: block;
	line-height: 1;
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	border: none;
	font-size: 2rem;
}
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.pr-qty
	.s-container
	input#qtyItem {
	width: 3.5rem;
	font-size: 2rem;
	margin: 0;
	padding: 0 0px;
	border: none;
	text-align: center;
}
.checkout-section
	.checkout.content
	.product-table-head
	.product-table-body
	.pr-qty
	.s-container
	input#qtyItem::-webkit-inner-spin-button {
	appearance: none;
}
.checkout-section .checkout.content .total-container {
	font-size: 2rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}
.checkout-section .checkout.content .total-container button.submit-btn {
	width: 20rem;
	margin-bottom: 2rem;
}
.checkout-section .checkout.content .total-container button.submit-btn:focus {
	outline: none;
}
.checkout-section .checkout.content .total-container span {
	font-size: 2rem;
	font-weight: 400;
	margin-right: 1rem;
	text-transform: uppercase;
}
.checkout-section .checkout.content .client-details {
	margin-left: 4rem;
	padding: 2rem;
	background-color: #242d3e;
	border-radius: 1rem;
	width: 30%;
}
.checkout-section .checkout.content .client-details .cd-container {
	font-size: 1rem;
	font-weight: 400;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	height: 100%;
}
.checkout-section .checkout.content .client-details .cd-container h1 {
	display: block;
	font-size: 3rem;
	letter-spacing: -1px;
	padding-bottom: 3rem;
	border-bottom: 1px solid #ffffff2f;
	color: #ffffff;
}
.checkout-section
	.checkout.content
	.client-details
	.cd-container
	.info-container {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: 100%;
}
.checkout-section
	.checkout.content
	.client-details
	.cd-container
	.info-container
	.info-text {
	margin-bottom: 2rem;
}
.checkout-section
	.checkout.content
	.client-details
	.cd-container
	.input-container {
	font-size: 1.5rem;
	color: #ffffff62;
}
.checkout-section
	.checkout.content
	.client-details
	.cd-container
	.input-container
	input {
	width: 100%;
	padding: 1rem;
	font-size: 2rem;
	border: none;
	background: none;
	color: #fff;
	border-bottom: 1px solid #fff;
	outline: none;
}
.checkout-section
	.checkout.content
	.client-details
	.cd-container
	.btn-container {
	position: relative;
}
.checkout-section
	.checkout.content
	.client-details
	.cd-container
	.btn-container
	button.submit-btn:focus {
	outline: none;
}
.checkout-section
	.checkout.content
	.client-details
	.cd-container
	.btn-container
	button.submit-btn
	i::after {
	font-family: 'FontAwesome';
	margin-left: 1rem;
	content: '\f1d8';
	display: inline-block;
}
.checkout-section
	.checkout.content
	.client-details
	.cd-container
	.btn-container
	.spinner {
	margin-left: 7rem;
}
.checkout-section
	.checkout.content
	.client-details
	.cd-container
	.btn-container
	.spinner
	> div {
	width: 10px;
	height: 10px;
	margin-left: 2px;
	background-color: #fff;
}

.banner {
	padding: 5rem;
	border: 2px solid orangered;
}

footer {
	display: block;
	position: absolute;
	bottom: 0px;
	z-index: 10;
}

.footer-section {
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 5rem;
	background-color: #333333;
	color: #f3f3ee;
}
.footer-section .footer-text {
	font-size: 1.6rem;
}
.footer-section .footer-text h2 {
	color: #ffffff;
}

.shopping-cart {
	display: flex;
	width: 100%;
	max-height: 25px;
	outline: none;
	height: 100%;
	cursor: pointer;
}
.shopping-cart #shopping-cart__logo {
	height: 100%;
}
.shopping-cart .shopping-cart__number-items {
	background: #02a7bb;
	border-radius: 50px;
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 1.5rem;
	width: 20px;
	height: 20px;
	text-align: center;
	justify-content: center;
	transform: translate(-15px, -5px);
}
.shopping-cart .shopping-cart-items__value {
	width: 13px;
	font-size: 1rem;
	font-weight: 400;
	color: #ffffff;
	text-align: center;
	vertical-align: middle;
	margin-bottom: 0;
	height: 13px;
	transform: translate(0px, -2px);
}
.shopping-cart #shopping-cart-total {
	position: absolute;
	font-size: 1.5rem;
	transform: translate(55px, 6px);
	text-align: center;
	width: 125px;
}
.shopping-cart #shopping-cart-total h2 {
	width: 100%;
	color: #fff;
}
.shopping-cart #shopping-cart-total:after {
	display: block;
	content: '';
	background-color: #174b8a;
	height: 100%;
	position: absolute;
	width: 100%;
	top: 0;
	z-index: -1;
	border-radius: 25px;
}

.shopping-cart-modal {
	opacity: 1;
	transition: all 0.15459s;
	z-index: 20;
	display: flex;
	flex-direction: column;
	top: 56px;
	right: 0;
	position: absolute;
	max-width: 350px;
	background: #fff;
	width: 100%;
	padding: 20px;
	color: #000;
	font-size: 1.3rem;
	line-height: 1.2;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
		0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
		0 16px 16px rgba(0, 0, 0, 0.12);
}
.shopping-cart-modal.active {
	opacity: 1;
	z-index: 100;
}
.shopping-cart-modal .shopping-cart-modal__content .shopping-cart-modal__body {
	display: flex;
	flex-direction: column;
}
.shopping-cart-modal
	.shopping-cart-modal__content
	.shopping-cart-modal__body
	.shopping-cart-modal-body.__items {
	font-size: 1.5rem;
	display: flex;
	align-items: flex-start;
	padding: 1rem 0;
}
.shopping-cart-modal
	.shopping-cart-modal__content
	.shopping-cart-modal__body
	.shopping-cart-modal-body.__items
	img {
	align-self: flex-start;
	max-width: 50px;
	width: 100%;
	margin-right: 20px;
}
.shopping-cart-modal
	.shopping-cart-modal__content
	.shopping-cart-modal__body
	.shopping-cart-modal-body.__items
	svg {
	max-width: 50px;
	max-height: 50px;
	width: 100%;
	margin-right: 10px;
}
.shopping-cart-modal
	.shopping-cart-modal__content
	.shopping-cart-modal__body
	.shopping-cart-modal-body.__qty {
	font-weight: 300;
	max-width: 165px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-left: auto;
	padding-bottom: 1rem;
}
.shopping-cart-modal
	.shopping-cart-modal__content
	.shopping-cart-modal__body
	.shopping-cart-modal-body.__qty
	p {
	letter-spacing: 0.5px;
}
.shopping-cart-modal
	.shopping-cart-modal__content
	.shopping-cart-modal__body
	.shopping-cart-modal-body.__qty
	span {
	font-size: 1.6rem;
	display: flex;
	align-items: center;
	width: 9rem;
}
.shopping-cart-modal
	.shopping-cart-modal__content
	.shopping-cart-modal__body
	.shopping-cart-modal-body.__qty
	span
	h4 {
	margin-bottom: 0;
}
.shopping-cart-modal
	.shopping-cart-modal__content
	.shopping-cart-modal__body
	.shopping-cart-modal-body.__qty
	span
	svg {
	opacity: 0.5;
	width: 17px;
	margin-left: 0.5rem;
	cursor: pointer;
}
.shopping-cart-modal .shopping-cart-modal__content .shopping-cart-modal__total {
	font-size: 1.8rem;
	display: flex;
	padding: 1rem 0 2rem;
	font-weight: 400;
}
.shopping-cart-modal
	.shopping-cart-modal__content
	.shopping-cart-modal__total
	span {
	margin-left: auto;
	justify-self: flex-end;
	font-weight: 900;
}
.shopping-cart-modal .checkout-btn {
	width: 100%;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 1.7rem;
	padding: 1.8rem;
	border: none;
	background-color: #02a7bb;
	color: #ffffff;
	transition: background-color 0.3s ease;
	cursor: pointer;
}
.shopping-cart-modal .checkout-btn:hover {
	background-color: #039abb;
}
.shopping-cart-modal .checkout-btn:active,
.shopping-cart-modal .checkout-btn:focus {
	outline: none;
}
.shopping-cart-modal .spinner-container {
	top: 0;
	left: 0;
}
.shopping-cart-modal hr.shopping-cart-window__hr {
	border: 1px solid #eeeeee;
}

.image__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 60%;
	min-width: 60%;
	min-height: 400px;
	max-height: 400px;
	height: 100%;
}
.image__container .main-image__container {
	margin: 0 auto;
	width: 100%;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
	max-height: 400px !important;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
	text-align: left !important;
	display: flex !important;
	align-items: center !important;
}

.image-gallery-thumbnail {
	width: 70px !important;
	height: 70px !important;
}

.detail-section .bottom {
	padding-top: 0 !important;
}

.image-gallery-content .image-gallery-slide > img {
	max-height: 100%;
	max-width: 100%;
	object-fit: cover;
}

.image-gallery-thumbnails-wrapper {
	max-width: 350px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner,
.image-gallery-thumbnail .image-gallery-thumbnail-image {
	height: 100%;
}

#customer-table {
	min-height: 100vh;
	padding-top: 5rem;
}

@media only screen and (min-width: 320px) and (max-width: 567px) {
	html {
		font-size: 35%;
	}
	img {
		max-width: 100%;
		max-height: 200px;
		object-fit: cover;
	}
	.header {
		padding: 1rem 1rem;
	}
	.header .header-content .left.text span {
		text-align: right;
	}
	.header .header-content .left.text .logo-wrapper {
		width: 40px;
	}
	.header
		.header-content
		.left.text
		.logo-wrapper
		div
		> .shopping-cart-modal {
		top: 40px;
		left: 5px;
	}
	.header .shopping-cart {
		height: 100%;
	}
	.header .shopping-cart #shopping-cart__logo svg {
		width: 25px;
		height: 25px;
	}
	.header .shopping-cart .shopping-cart__number-items {
		width: 15px;
		height: 15px;
	}
	.header
		.shopping-cart
		.shopping-cart__number-items
		.shopping-cart-items__value {
		font-size: 2rem;
		line-height: 2.5;
		text-align: center;
		transform: translate(0px, -9px);
	}
	.hero-section .hero-img .hero-content > h2 {
		padding: 0 2rem;
	}
	.image-gallery-content .image-gallery-slide .image-gallery-image {
		max-height: 200px !important;
	}
	.video-player {
		width: 100% !important;
		height: 200px !important;
	}
	.navbar {
		display: none;
	}
	.products-section .products-row {
		grid-template-columns: repeat(2, 1fr);
		padding: 0 2rem;
	}
	.detail-container .detail-content {
		display: flex;
		flex-direction: column;
	}
	.detail-container .detail-content .img {
		max-width: 100%;
		width: 100%;
	}
	.detail-container .detail-content .img img {
		width: 100%;
		max-width: 100%;
	}
	.detail-container .detail-content .text-container {
		width: 100%;
		padding: 0 2rem;
	}
	.detail-container .detail-content .text-container .title {
		font-size: 3rem;
		margin-top: 0.5em;
		padding-bottom: 0;
	}
	.detail-container .detail-content .text-container .order-btn {
		width: 20rem;
		padding: 2rem 2rem;
		font-size: 2.5rem;
	}
	.detail-container
		.detail-content
		.text-container
		.buy-quantity-container
		.box {
		padding: 1em;
		width: 20rem;
		height: auto;
		font-size: 2rem;
	}
	.checkout-section {
		display: flex;
		flex-direction: column;
	}
	.checkout-section .checkout.content {
		padding: 4rem;
		font-size: 1.5rem;
		font-weight: 700;
	}
	.checkout-section .checkout.content .checkout-cart {
		flex-direction: column;
	}
	.checkout-section .checkout.content .checkout-cart .cancel-btn {
		display: block;
	}
	.checkout-section .checkout.content .product-content {
		margin-bottom: 2em;
	}
	.checkout-section .checkout.content .product-content img {
		height: 50px;
	}
	.checkout-section .checkout.content .checkout-cart-container {
		width: 100%;
	}
	.checkout-section .checkout.content .checkout-cart-container .customerNote {
		margin-bottom: 5rem;
	}
	.checkout-section
		.checkout.content
		.checkout-cart-container
		.customerNote
		textarea {
		width: 100%;
	}
	.checkout-section
		.checkout.content
		.checkout-cart-container
		.total-container {
		flex-direction: column;
		padding: 2em 0;
	}
	.checkout-section
		.checkout.content
		.checkout-cart-container
		.total-container
		.submit-btn {
		display: none;
		order: 3;
		width: 100%;
	}
	.checkout-section .checkout.content .client-details {
		width: 100%;
		margin-left: 0;
		margin-bottom: 2em;
	}
	.checkout-section
		.checkout.content
		.client-details
		.cd-container
		#customerAddress {
		margin-bottom: 3em;
	}
	.checkout-section .checkout.content .client-details .submit-btn {
		padding: 1.5em;
	}
	.checkout-section .checkout.content .cancel-btn {
		order: 3;
		width: 100%;
		padding: 2em;
	}
	.detail-section .description-text {
		padding: 5rem 3rem;
	}
	.image__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 400px;
		max-height: 400px;
	}
	.image__container .main-image__container {
		max-width: 200px;
		min-width: 200px;
	}
	.image__container .main-image__container img {
		max-width: 100%;
		object-fit: cover;
	}
	.detail-container {
		padding: 0;
	}
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
	html {
		font-size: 50%;
	}
	.navbar {
		display: none;
	}
	.products-section .products-row {
		grid-template-columns: repeat(4, 1fr);
		padding: 0 2rem;
	}
	.detail-container .detail-content {
		display: flex;
		flex-direction: column;
	}
	.detail-container .detail-content .text-container {
		width: 100%;
	}
	.detail-container .detail-content .img {
		width: 100%;
	}
	.image-gallery-content .image-gallery-slide .image-gallery-image {
		max-height: 200px !important;
	}
	.detail-container
		.detail-content
		.text-container
		.buy-quantity-container
		.box {
		padding: 1em;
		width: 15rem;
		height: auto;
		font-size: 1.5rem;
	}
	.detail-container .detail-content .text-container .order-btn {
		width: 18rem;
		padding: 2rem 1rem;
		font-size: 2rem;
	}
}

@media only screen and (min-width: 900px) and (max-width: 1400px) {
	html {
		font-size: 60%;
	}
	.header .left.text {
		width: auto;
	}
	.header .left.text span p {
		font-size: 2rem;
	}
	.shopping-cart {
		max-height: none;
	}
}

#add-btn,
#save-btn {
	padding: 1rem;
}

.MuiTableCell-root {
	font-size: 1.5rem !important;
}
