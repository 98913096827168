.dialog-modal__background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0000008e;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: 0;
  transition: all .1s ease; }
  .dialog-modal__background.open {
    z-index: 12;
    opacity: 1; }
  .dialog-modal__background div.dialog-modal_container {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #fff;
    text-align: center;
    padding: 3rem;
    overflow: hidden;
    transform: translate(0, 20rem);
    transition: all .4s ease;
    opacity: 0; }
    .dialog-modal__background div.dialog-modal_container.active {
      opacity: 1;
      transform: translate(0, 0); }
    .dialog-modal__background div.dialog-modal_container .img-wrapper {
      width: 20rem; }
      .dialog-modal__background div.dialog-modal_container .img-wrapper img {
        width: 100%;
        height: 100%; }
    .dialog-modal__background div.dialog-modal_container .text-wrapper {
      position: relative;
      top: -5rem; }
      .dialog-modal__background div.dialog-modal_container .text-wrapper h2,
      .dialog-modal__background div.dialog-modal_container .text-wrapper h3 {
        color: #000;
        margin-bottom: 2rem; }
      .dialog-modal__background div.dialog-modal_container .text-wrapper h2 {
        font-size: 2rem; }
      .dialog-modal__background div.dialog-modal_container .text-wrapper h3 {
        font-size: 1.8rem;
        margin-bottom: 3rem; }
      .dialog-modal__background div.dialog-modal_container .text-wrapper p {
        font-size: 1.5rem;
        margin-bottom: 2rem; }
