.m-b-5 {
  margin-bottom: 5rem; }

.modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 20; }

.modal-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #ffffffe0;
  overflow: auto; }
  .modal-bg form {
    width: 100%; }
  .modal-bg .modal-container {
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 3rem;
    background-color: white;
    color: #333;
    width: 50rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11), 0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11), 0 8px 16px rgba(0, 0, 0, 0.11); }
    .modal-bg .modal-container .modal-content {
      position: relative;
      top: 0;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center; }
      .modal-bg .modal-container .modal-content > img {
        margin-bottom: -60px;
        top: 0;
        left: 0;
        transform: translateY(-35px);
        width: 20rem; }
      .modal-bg .modal-container .modal-content .modal-text {
        min-height: 50%;
        text-align: center;
        width: 100%; }
        .modal-bg .modal-container .modal-content .modal-text h1 {
          font-size: 2rem;
          margin-bottom: 1.5rem; }
        .modal-bg .modal-container .modal-content .modal-text p {
          font-size: 1.8rem;
          line-height: 1.4; }
          .modal-bg .modal-container .modal-content .modal-text p .logos-container {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center; }
            .modal-bg .modal-container .modal-content .modal-text p .logos-container .logos svg:hover {
              cursor: pointer; }
          .modal-bg .modal-container .modal-content .modal-text p .number-steps {
            display: flex;
            align-items: center;
            width: 100%;
            text-align: left;
            font-size: 1.6rem; }
            .modal-bg .modal-container .modal-content .modal-text p .number-steps .number-steps__one,
            .modal-bg .modal-container .modal-content .modal-text p .number-steps .number-steps__two,
            .modal-bg .modal-container .modal-content .modal-text p .number-steps .number-steps__three {
              align-self: flex-start;
              flex-shrink: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              width: 25px;
              height: 25px;
              border-radius: 50px;
              background-color: #1E5A99;
              margin-right: 1rem; }
      .modal-bg .modal-container .modal-content .input-container {
        width: 100%;
        padding: 2rem 0;
        margin-top: 2rem; }
        .modal-bg .modal-container .modal-content .input-container input {
          font-size: 2rem;
          width: 100%;
          padding: 2rem 1rem;
          text-align: center;
          border: 1px solid #ddd;
          transition: all .2s ease;
          text-transform: uppercase; }
          .modal-bg .modal-container .modal-content .input-container input::placeholder {
            color: #ddd;
            font-size: 1.5rem;
            text-align: center;
            vertical-align: middle;
            display: flex;
            align-items: center;
            transform-origin: 0 20%;
            transform: translate(1px 5px); }
          .modal-bg .modal-container .modal-content .input-container input:focus {
            outline: none;
            border: 1px solid #1E5A99; }
      .modal-bg .modal-container .modal-content .btn-container {
        width: 100%;
        max-height: fit-content;
        position: relative; }
        .modal-bg .modal-container .modal-content .btn-container .spinner-container {
          position: absolute; }
          .modal-bg .modal-container .modal-content .btn-container .spinner-container .spinner > div {
            background-color: #1E5A99; }
      .modal-bg .modal-container .modal-content #ok-btn {
        display: flex;
        justify-content: center;
        align-self: flex-end;
        font-size: 2.5rem;
        transition: all .2s ease; }
        .modal-bg .modal-container .modal-content #ok-btn:hover {
          background: #1e68b8; }
        .modal-bg .modal-container .modal-content #ok-btn:focus {
          outline: none; }
        .modal-bg .modal-container .modal-content #ok-btn svg {
          margin-left: 2rem; }

@media only screen and (min-width: 320px) {
  .modal {
    position: fixed; }
  .modal-bg .modal-container {
    font-size: 60%;
    min-height: 15rem;
    height: 90%;
    overflow-y: auto; }
    .modal-bg .modal-container .modal-content {
      height: 100%;
      display: block; }
  .modal-bg .modal-container .modal-content .modal-text p .logos-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center; } }

@media only screen and (min-width: 568px) {
  .modal-bg .modal-container {
    font-size: 70%;
    height: 90%;
    overflow-y: auto; }
    .modal-bg .modal-container .modal-content {
      height: 100%;
      display: block; }
      .modal-bg .modal-container .modal-content .modal-text p .logos-container {
        flex-direction: row; } }

@media only screen and (min-width: 767px) {
  .modal-bg .modal-container {
    font-size: 70%;
    height: 73%;
    overflow-y: auto; }
    .modal-bg .modal-container .modal-content {
      height: 100%;
      display: block; } }

@media only screen and (min-width: 900px) {
  .modal-bg .modal-container {
    font-size: 70%;
    height: 95%;
    overflow-y: auto; }
    .modal-bg .modal-container .modal-content {
      height: 100%;
      display: block; } }

@media only screen and (min-width: 1024px) {
  .modal-bg .modal-container {
    font-size: 70%;
    width: 70rem;
    height: 58%;
    overflow-y: auto; }
    .modal-bg .modal-container .modal-content {
      height: 100%;
      display: block; } }

@media only screen and (min-width: 1360px) {
  .modal-bg .modal-container {
    height: 78%;
    overflow-y: auto; } }
